import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useLocation } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const navData1 = [
  {
    id: 0,
    icon: <HomeIcon />,
    text: "Home",
    link: "/Overview",
  },
  {
    id: 1,
    icon: <InventoryIcon />,
    text: "Inventory",
    link: "/Inventory",
    subcategories: [
      {
        id: 0,
        text: "Items",
        link: "/Items",
      },
      {
        id: 1,
        text: "Item Group",
        link: "/ItemGroup",
      },
      {
        id: 2,
        text: "Inventory Adjustments",
        link: "/InventoryAdjustment",
      },
    ],
  },
  {
    id: 2,
    icon: <ShoppingCartIcon />,
    text: " Sales",
    link: "/Sales",
    subcategories: [
      {
        id: 0,
        text: "Customers ",
        link: "/Customers",
      },
      {
        id: 1,
        text: " Sales Orders ",
        link: "/ SalesOrders",
      },
      {
        id: 3,
        text: "Packages ",
        link: "/Packages",
      },
      {
        id: 4,
        text: "Shipments ",
        link: "/Shipments",
      },
      {
        id: 5,
        text: "Delivery Challans ",
        link: "/DeliveryChallans",
      },
      {
        id: 6,
        text: "  Invoices ",
        link: "/  Invoices",
      },
      {
        id: 7,
        text: "Payments Received ",
        link: "/Payments Received",
      },
      {
        id: 8,
        text: "Sales Returns ",
        link: "/Sales Returns",
      },
      {
        id: 9,
        text: "Credit Notes",
        link: "/Credit Notes",
      },
    ],
  },
  {
    id: 3,
    icon: <ShoppingBagIcon />,
    text: "Purchases",
    link: "/Purchase",
    subcategories: [
      {
        id: 0,
        text: "Vendors ",
        link: "/Vendors",
      },
      {
        id: 1,
        text: "Expenses",
        link: "/Expenses",
      },
      {
        id: 2,
        text: "Purchase Orders",
        link: "/PurchaseOrders",
      },
      {
        id: 3,
        text: "  Purchase Receives",
        link: "/PurchaseReceives",
      },
      {
        id: 4,
        text: " Bills",
        link: "/Bills",
      },

      {
        id: 5,
        text: "  Payments Made",
        link: "/PaymentsMade",
      },
      {
        id: 6,
        text: "Vendor Credits",
        link: "/VendorCredits",
      },
    ],
  },
  {
    id: 4,
    icon: <ElectricalServicesIcon className="clr" />,
    text: "Integrations",
    link: "/Intigration",
  },

  {
    id: 5,
    icon: <SignalCellularAltIcon />,
    text: "Reports",
    link: "/Reports",
  },
  {
    id: 6,
    icon: <FolderIcon />,
    text: "Documents",
    link: "/Documents",
  },
  {
    id: 7,
    icon: <ExitToAppIcon />,
    text: "Logout",
    link: "/Logout",
  },
];
const Sidenav1 = () => {
  const [open, setOpen] = useState(true);
  const [navData, setNavData] = useState(navData1);

  const location = useLocation();

  const isActive1 = (path) => {
    return window.location.pathname === path;
  };
  const toggleOpen = () => {
    setOpen(!open);
  };

  const toggleCategory = (categoryId) => {
    setNavData((prevNavData) => {
      return prevNavData.map((item) => {
        if (item.id === categoryId) {
          return {
            ...item,
            isExpanded: !item.isExpanded,
          };
        }
        return item;
      });
    });
  };

  return (
    <div className={open ? "sidenav" : "sidenavClosed"}>
      <button className="menuBtn" onClick={toggleOpen}>
        {open ? (
          <KeyboardDoubleArrowLeftIcon />
        ) : (
          <KeyboardDoubleArrowRightIcon />
        )}
      </button>
      <div className="row ul_list">
        <li
          style={{
            width: "88%",
            borderBottom: "1px solid grey",
            display: open ? "flex" : "none",
            marginBottom: "15px",
            position: "sticky",
            top: "0%",
            left: "0%",
          }}
        >
          <NavLink
            to="/"
            className="link_tag img"
            style={{ textDecoration: "none" }}
          >
            <div>
              {/* <img
                width={"80px"}
                height={"30px"}
                src="..\Assests\Gauge_16.jpg"
                alt=""
              /> */}
              <h6>R.R.Industries</h6>
            </div>
          </NavLink>
        </li>
        <div className="ul_sty" style={{ width: "94%" }}>
          {navData.map((item) => (
            <li key={item.id}>
              {item.subcategories && open ? (
                <div>
                  <NavLink
                    className={
                      isActive1(item.link) ? "sideitem active1" : "sideitem"
                    }
                    activeClassName="active1"
                    to={item.link}
                    onClick={() => toggleCategory(item.id)}
                  >
                    <span onClick={toggleOpen}>{item.icon}</span>
                    <span className={open ? "linkText" : "linkTextClosed"}>
                      {item.text}
                      {item.isExpanded ? (
                        <ArrowDropDownIcon />
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </span>
                  </NavLink>
                  {item.isExpanded && (
                    <ul style={{ padding: "5px" }}>
                      {item.subcategories.map((subcategory) => (
                        <li key={subcategory.id}>
                          <NavLink
                            className={
                              isActive1(subcategory.link)
                                ? "sideitem active1"
                                : "sideitem"
                            }
                            activeClassName="active1"
                            to={subcategory.link}
                          >
                            <span
                              className={
                                open ? "subLinkText" : "subLinkTextClosed"
                              }
                            >
                              {subcategory.text}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ) : (
                <NavLink
                  className={
                    isActive1(item.link) ? "sideitem active1" : "sideitem"
                  }
                  activeClassName="active1"
                  to={item.link}
                >
                  {item.icon}
                  <span className={open ? "linkText" : "linkTextClosed"}>
                    {item.text}
                  </span>
                </NavLink>
              )}
            </li>
          ))}{" "}
        </div>
      </div>
    </div>
  );
};

export default Sidenav1;
