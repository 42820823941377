import { Card } from "@mui/material";
import React from "react";
import Form from "react-bootstrap/Form";
import Overview from "./Overview";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import DescriptionIcon from "@mui/icons-material/Description";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip as RechartsTooltip, // Rename the Recharts Tooltip component
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
} from "recharts";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Table from "react-bootstrap/Table";
import OverViewFooter from "./OverViewFooter";

const data02 = [{ date: "Group A", value: 2400 }];

function Dashboard() {
  const data = [
    {
      date: "01 june",
      "Rs.": 500,
    },
    {
      date: "02 june",
      "Rs.": 1000,
    },
    {
      date: "03 june",
      "Rs.": 2000,
    },
    {
      date: "04 june",
      "Rs.": 3000,
    },
    {
      date: "05 june",
      "Rs.": 4000,
    },
    {
      date: "06 june",
      "Rs.": 5000,
    },
    {
      date: "07 june",
      "Rs.": 6000,
    },
    {
      date: "08 june",
      "Rs.": 7000,
    },
    {
      date: "09 june",
      "Rs.": 8000,
    },
    {
      date: "10 june",
      "Rs.": 10000,
    },
    {
      date: "11 june",
      "Rs.": 11000,
    },
    {
      date: "12 june",
      "Rs.": 12000,
    },
    {
      date: "13 june ",
      "Rs.": 13000,
    },
    {
      date: "14 june",
      "Rs.": 13000,
    },
    {
      date: "15june",
      "Rs.": 14000,
    },
  ];
  const formatYAxisLabel = (value) => {
    if (value >= 1000) {
      return `${value / 1000}k`;
    }
    return value;
  };
  return (
    <>
      <Overview />
      <div className="row  mt-3 top bottom">
        <Card
          className="row m-auto p-5"
          style={{
            backgroundColor: "#F1F6F9",
          }}
        >
          <div className="col-md-8">
            <div className="row">
              <h5>Sales Activity</h5>
              <Card className="col-md-3 m-1 hcard p-2">
                <div className="row text-center">
                  <OverlayTrigger
                    placement="top"
                    trigger="hover"
                    overlay={<Tooltip>4.4</Tooltip>}
                  >
                    <span className="f_28" style={{ color: "#068fffec" }}>
                      5
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="row text-center">
                  <span>Qty</span>
                </div>
                <div className="row text-center">
                  <span className="col-md-2 ">
                    <CheckCircleOutlineOutlinedIcon className="fnt_15" />
                  </span>

                  <div className="col-md-10">
                    <span className="row p-1"> TO BE PACKED</span>
                  </div>
                </div>
              </Card>
              <Card className="col-md-3 m-1 hcard p-2">
                <div className="row text-center">
                  <OverlayTrigger
                    placement="top"
                    trigger="hover"
                    overlay={<Tooltip></Tooltip>}
                  >
                    <span className="f_28" style={{ color: "red" }}>
                      0
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="row text-center">
                  <span>Pkgs</span>
                </div>
                <div className="row text-center">
                  <span className="col-md-2 ">
                    <LocalShippingIcon className="fnt_15" />
                  </span>
                  <div className="col-md-10">
                    <span className="row p-1"> TO BE SHIPPED</span>
                  </div>
                </div>
              </Card>
              <Card className="col-md-3 m-1 hcard p-2">
                <div className="row text-center">
                  <OverlayTrigger
                    placement="top"
                    trigger="hover"
                    overlay={<Tooltip></Tooltip>}
                  >
                    <span className="f_28" style={{ color: "green" }}>
                      0
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="row text-center">
                  <span>Pkgs</span>
                </div>
                <div className="row text-center">
                  <span className="col-md-2 ">
                    <DeliveryDiningIcon className="fnt_15" />
                  </span>
                  <div className="col-md-10">
                    <span className="row p-1"> TO BE DELIVERED</span>
                  </div>
                </div>
              </Card>
              <Card className="col-md-3 m-1 hcard p-2">
                <div className="row text-center">
                  <OverlayTrigger
                    placement="top"
                    trigger="hover"
                    overlay={<Tooltip>4</Tooltip>}
                  >
                    <span className="f_28" style={{ color: "#ffcc00" }}>
                      8
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="row text-center">
                  <span>Qty</span>
                </div>
                <div className="row ">
                  <span className="col-md-2 ">
                    <DescriptionIcon className="fnt_15" />
                  </span>
                  <div className="col-md-10">
                    <span className="row p-1"> TO BE INVOICED</span>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row">
              <h5>  R.R.Industries Summary</h5>
            </div>
            <div className="row ">
              <Card className=" mb-3 hcard">
                <div className="row p-1">
                  <p className="col-md-8 m-auto">QUANTITY IN HAND</p>{" "}
                  <p className="col-md-4 m-auto " style={{ fontSize: "27px" }}>
                    23300
                  </p>
                </div>
              </Card>
            </div>
            <div className="row">
              <Card className=" mb-3 hcard">
                <div className="row p-1">
                  <p className="col-md-9 m-auto">QUANTITY TO BE RECEIVED</p>{" "}
                  <p className="col-md-3 m-auto" style={{ fontSize: "27px" }}>
                    433
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      </div>

      <div className="row m-auto mt-5">
        <Card className=" m-auto p-2" style={{ height: "10%", width: "500px" }}>
          <div className="row  bottom p-2">
            <h6>PRODUCT DETAILS </h6>
          </div>
          <div className="row ">
            <div className="col-md-6">
              <div className="row p-2">
                <div className="col-md-9">
                  <p className="gclr" style={{ color: "red" }}>
                    Low Stock Items
                  </p>
                  <p className="gclr">All Items Groups</p>
                  <p className="gclr">All Items </p>
                </div>
                <div className="col-md-3 ">
                  <p className="gclr f_20" style={{ color: "red" }}>
                    0
                  </p>
                  <p className="gclr f_20">0</p>
                  <p className="gclr f_20" style={{ color: "green" }}>
                    10
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 left text-center ">
              <h6 className="gclr">Active Items</h6>
              <div style={{ position: "relative", bottom: "6%", left: "2%" }}>
                <PieChart width={200} height={200}>
                  <Pie
                    dataKey="value"
                    data={data02}
                    cx={100}
                    cy={100}
                    innerRadius={20}
                    outerRadius={40}
                    fill="#82ca9d"
                  />
                  <Tooltip />
                </PieChart>
              </div>
            </div>
          </div>
        </Card>
        <Card
          className="col-md-5 m-auto p-2"
          style={{ height: "10%", width: "500px" }}
        >
          <div className="row  bottom p-2">
            <div className="col-md-6">
              <h6>TOP SELLING ITEMS</h6>
            </div>
            <div className="col-md-6">
              <div className="row text-end">
                <div className="col-md-6">
                  <CalendarMonthIcon style={{ fontSize: "28px" }} />
                </div>
                <select value={"Today"} className="col-md-6">
                  <option>Today </option>
                  <option>This week</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row text-center " style={{ padding: "95.29px" }}>
            <p className="m-auto">No items were invoiced in this time frame</p>
          </div>
        </Card>
      </div>
      <div className="row m-auto mt-5">
        <Card
          className="col-md-5 m-auto p-2"
          style={{ height: "10%", width: "500px" }}
        >
          <div className="row  bottom p-2">
            <div className="col-md-6">
              <h6>PURCHASE ORDER </h6>
            </div>
            <div className="col-md-6">
              <div className="row text-end">
                <div className="col-md-6">
                  <CalendarMonthIcon style={{ fontSize: "28px" }} />
                </div>
                <select value={"Today"} className="col-md-6">
                  <option>Today </option>
                  <option>This week</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <p> Quantity ordered </p>
            <p style={{ fontSize: "25px", color: "#068fff" }}>0</p>
          </div>
          <div className="row text-center">
            <p>Total Cost </p>
            <p style={{ fontSize: "25px", color: "#068fff" }}>Rs.34.00</p>
          </div>
        </Card>
        <Card
          className="col-md-5 m-auto p-2"
          style={{ height: "10%", width: "500px" }}
        >
          <div className="row  bottom p-2">
            <div className="col-md-6">
              <h6>SALES ORDER</h6>
            </div>
            <div className="col-md-6">
              <div className="row text-end">
                <div className="col-md-6">
                  <CalendarMonthIcon style={{ fontSize: "28px" }} />
                </div>
                <select value={"Today"} className="col-md-6">
                  <option>Today </option>
                  <option>This week</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row ">
            <Table className="fnt " striped>
              <thead>
                <tr>
                  <th>Channel</th>
                  <th>Draft</th>
                  <th>Confirmed</th>
                  <th>Packed</th>
                  <th>Shipped</th>
                  <th>Invoiced</th>
                </tr>
              </thead>
            </Table>
          </div>
          <div className="row text-center" style={{ padding: "54px" }}>
            <p className="m-auto">No items were invoiced in this time frame</p>
          </div>
        </Card>
      </div>

      <div className="row m-auto mt-5">
        <Card style={{ width: "96%" }} className="m-auto p-2">
          <div className="row  bottom p-2">
            <div className="col-md-6">
              <h6>SALES ORDER SUMMARY(IN INR) </h6>
            </div>
            <div className="col-md-6">
              <div className="row text-end">
                <div className="col-md-9">
                  <CalendarMonthIcon style={{ fontSize: "28px" }} />
                </div>
                <select value={"Today"} className="col-md-3">
                  <option>Today </option>
                  <option>This week</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-10">
              <LineChart
                width={830}
                height={500}
                data={data}
                margin={{ top: 5, right: 30, bottom: 15 }}
              >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis dataKey="date" />
                <YAxis dataKey="Rs." tickFormatter={formatYAxisLabel} />{" "}
                <RechartsTooltip />
                {/* <Legend /> */}
                <Line type="monotone" dataKey="name" stroke="#8884d8" />
                <Line type="monotone" dataKey="Rs." stroke="#82ca9d" />
              </LineChart>
            </div>
            <div className="col-md-2">
              <p>Total Sales</p>
              <div
                className="row"
                style={{
                  border: "1px solid skyblue",
                  borderLeft: "4px solid #75C2F6",
                }}
              >
                <div className="col-md-2">
                  <span>
                    <FiberManualRecordIcon
                      style={{ fontSize: "12px", color: "#75C2F6" }}
                    />
                  </span>
                </div>
                <div className="col-md-10">
                  <p>DIRECT SALES</p>
                  <p>RS.00</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="row m-auto mt-5">
        <OverViewFooter />
      </div>
    </>
  );
}

export default Dashboard;
