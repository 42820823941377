import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import Header from "./Header";
import Footer from "./Footer";
import Button from "react-bootstrap/esm/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SearchIcon from "@mui/icons-material/Search";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Offcanvas from "react-bootstrap/Offcanvas";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NavDropdown from "react-bootstrap/NavDropdown";

function Items() {
  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);
  const [item, setItem] = useState(false);

  const handleRowSelected = (rows) => {
    console.log("Selected Rows:", rows);
  };

  const handleRowClicked = (row) => {
    console.log("Clicked Row:", row);
  };

  const basicData = {
    columns: ["Image", "Name", "SKU", "STOCK ON HAND", "REORDER LEVEL"],
    rows: [
      {
        info: {
          img: "../Assests/img.webp",
          Name: "Ceramic Mugs Set",
        },
        SKU: "0343",
        StockOn: "1,000.0",
      },
      {
        info: {
          img: "../Assests/img.webp",
          Name: "Ceramic Mugs Set",
        },
        SKU: "0343",
        StockOn: "1,000.0",
      },
      {
        info: {
          img: "../Assests/img.webp",
          Name: "Ceramic Mugs Set",
        },
        SKU: "0343",
        StockOn: "1,000.0",
      },
      {
        info: {
          img: "../Assests/img.webp",
          Name: "Ceramic Mugs Set",
        },
        SKU: "0343",
        StockOn: "1,000.0",
      },
    ],
  };

  const mappedData = basicData.rows.map((row, index) => ({
    id: index,
    Image: (
      <img src={row.info.img} alt={row.info.Name} style={{ width: "30px" }} />
    ),
    Name: row.info.Name,
    SKU: row.SKU,
    "STOCK ON HAND": row.StockOn,
    searchIconColumn: null,
  }));

  const [selected, setSelected] = useState("All Items");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleRowSelectedList = (e) => {
    const selectedItem = e.target.innerText.trim();
    setSelected(selectedItem);
    setShowDropdown(false);
  };

  const handleDropdownToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  return (
    <>
      <Header />
      <div className="row m-3 ">
        <div className="col-md-3 p-0">
          <NavDropdown
            title={selected}
            show={showDropdown}
            onSelect={handleRowSelectedList}
            onToggle={handleDropdownToggle}
          >
            <ul>
              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner  "
              >
                All Items
              </li>

              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner "
              >
                {" "}
                Inactive Items
              </li>
              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner "
              >
                {" "}
                Un grouped Items
              </li>
              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner "
              >
                Low Stock Items
              </li>

              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner "
              >
                {" "}
                Inactive Items
              </li>
              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner  "
              >
                {" "}
                Un grouped Items
              </li>
              <li
                onClick={(e) => handleRowSelectedList(e)}
                className="col-md-11 p-1 list_inner  "
              >
                Low Stock Items
              </li>
            </ul>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/ItemCustompage">
              <div className="row">
                <div className="col-md-2">
                  {" "}
                  <AddCircleOutlineIcon />
                </div>
                <div className="col-md-10">
                  <p className="row m-auto">New Custom View</p>
                </div>
              </div>{" "}
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div className="col-md-9  ">
          <div className="row container_sty1">
            <Button className="col-md-1 m-1  " href="/NewItem">
              <div className="row ">
                <span className="col-md-1">
                  <AddOutlinedIcon
                    style={{ fontSize: "20px" }}
                    className=" m-auto"
                  />
                </span>
                <span className="col-md-6 text-center">
                  <span className="m-auto" style={{ fontSize: "12px" }}>
                    {" "}
                    New
                  </span>
                </span>
              </div>
            </Button>

            <Button
              variant="light"
              className="col-md-1 m-1 border1  text-center "
            >
              <div className="row  ">
                <span className="col-md-6 right1 m-auto ">
                  <ListOutlinedIcon
                    onClick={() => setItem(false)}
                    style={{ fontSize: "12px" }}
                    className="fnt_15 fnt"
                  />
                </span>
                <span className="col-md-6 ">
                  <div className="row  text-cener">
                    <span>
                      {" "}
                      <GridViewIcon
                        onClick={() => setItem(true)}
                        style={{ fontSize: "12px" }}
                        className="fnt m-auto"
                      />
                    </span>
                  </div>
                </span>
              </div>
            </Button>
            <Button
              variant="light"
              className="col-md-1 m-1 border1  text-center "
            >
              <div className="row">
                <span>
                  <MoreHorizOutlinedIcon />
                </span>
              </div>
            </Button>
            <Button
              variant="warning"
              className="col-md-1 m-1 border1  text-center "
            >
              <div className="row">
                <span>
                  <QuestionMarkIcon />
                </span>
              </div>
            </Button>
          </div>
        </div>
      </div>
      {!item ? (
        <>
          <div className="row m-auto">
            <div
              className="col-md-2"
              style={{
                position: "absolute",
                left: "14%",
                top: "26.5%",
                zIndex: "10",
              }}
            >
              <OverlayTrigger
                placement="top"
                trigger="hover"
                overlay={<Tooltip>Customize columns</Tooltip>}
              >
                <DashboardCustomizeIcon
                  onClick={handleShow8}
                  className="col-md-10"
                  style={{
                    left: "15%",
                    top: "26.5%",
                    zIndex: "100",
                    color: "skyblue",
                    fontSize: "15px",
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className="col-md-10">
              <SearchIcon
                className="float-end fnt fnt_15"
                style={{
                  position: "absolute",
                  right: "4%",
                  top: "27.5%",
                  zIndex: "10",
                }}
              />
            </div>
          </div>
          <div className="row m-auto">
            <DataTable
              columns={basicData.columns.map((column) => ({
                name: column,
                selector: column,
                sortable: true,
              }))}
              data={mappedData}
              selectableRows
              onSelectedRowsChange={handleRowSelected}
              onRowClicked={handleRowClicked}
              selectableRowsComponentProps={{ inkDisabled: true }}
              pagination
            />
          </div>
        </>
      ) : (
        <div className="row m-auto">
          <a
            className="col-md-3 m-2 p-3 card hcard hcard1 text-center anchore_Tag"
            href="/ItemDetails"
          >
            <img
              className="m-auto"
              width={"100px"}
              height={"100px"}
              src="../Assests/download (1).jpg"
              alt=""
            />
            <p className="ptag">Ceramic Mugs Set</p>
            <p className="ptag">
              <span>SKU : </span> <span className="fnt">003 </span>
            </p>

            <p className="ptag">
              <span>Stock on Hand : </span>
              <span style={{ color: "green" }}>1,000.00pcs</span>
            </p>
            <p className="ptag">
              <span> Selling Price : </span>
              <span className="fnt">Rs.1,000.00</span>
            </p>
            <p className="ptag">
              <span> Cost Price : </span>
              <span className="fnt">Rs.400.00</span>
            </p>
          </a>
          <a
            href="/ItemDetails"
            className="col-md-3 m-2 p-3 card hcard hcard1 text-center anchore_Tag"
          >
            <img
              className="m-auto"
              width={"100px"}
              height={"100px"}
              src="../Assests/img.webp"
              alt=""
            />
            <p className="ptag">Ceramic Mugs Set</p>
            <p className="ptag">
              <span>SKU : </span> <span className="fnt">003 </span>
            </p>

            <p className="ptag">
              <span>Stock on Hand : </span>
              <span style={{ color: "green" }}>1,000.00pcs</span>
            </p>
            <p className="ptag">
              <span> Selling Price : </span>
              <span className="fnt">Rs.1,000.00</span>
            </p>
            <p className="ptag">
              <span> Cost Price : </span>
              <span className="fnt">Rs.400.00</span>
            </p>
          </a>
          <a
            href="/ItemDetails"
            className="col-md-3 m-2 p-3 card hcard hcard1 text-center anchore_Tag"
          >
            <img
              className="m-auto"
              width={"100px"}
              height={"100px"}
              src="../Assests/img.webp"
              alt=""
            />
            <p className="ptag">Ceramic Mugs Set</p>
            <p className="ptag">
              <span>SKU : </span> <span className="fnt">003 </span>
            </p>

            <p className="ptag">
              <span>Stock on Hand : </span>
              <span style={{ color: "green" }}>1,000.00pcs</span>
            </p>
            <p className="ptag">
              <span> Selling Price : </span>
              <span className="fnt">Rs.1,000.00</span>
            </p>
            <p className="ptag">
              <span> Cost Price : </span>
              <span className="fnt">Rs.400.00</span>
            </p>
          </a>
          <a
            href="/ItemDetails"
            className="col-md-3 m-2 p-3 card hcard hcard1 text-center anchore_Tag"
          >
            <img
              className="m-auto"
              width={"100px"}
              height={"100px"}
              src="../Assests/img.webp"
              alt=""
            />
            <p className="ptag">Ceramic Mugs Set</p>
            <p className="ptag">
              <span>SKU : </span> <span className="fnt">003 </span>
            </p>

            <p className="ptag">
              <span>Stock on Hand : </span>
              <span style={{ color: "green" }}>1,000.00pcs</span>
            </p>
            <p className="ptag">
              <span> Selling Price : </span>
              <span className="fnt">Rs.1,000.00</span>
            </p>
            <p className="ptag">
              <span> Cost Price : </span>
              <span className="fnt">Rs.400.00</span>
            </p>
          </a>
          <a
            href="/ItemDetails"
            className="col-md-3 m-2 p-3 card hcard hcard1 text-center anchore_Tag"
          >
            <img
              className="m-auto"
              width={"100px"}
              height={"100px"}
              src="../Assests/img.webp"
              alt=""
            />
            <p className="ptag">Ceramic Mugs Set</p>
            <p className="ptag">
              <span>SKU : </span> <span className="fnt">003 </span>
            </p>

            <p className="ptag">
              <span>Stock on Hand : </span>
              <span style={{ color: "green" }}>1,000.00pcs</span>
            </p>
            <p className="ptag">
              <span> Selling Price : </span>
              <span className="fnt">Rs.1,000.00</span>
            </p>
            <p className="ptag">
              <span> Cost Price : </span>
              <span className="fnt">Rs.400.00</span>
            </p>
          </a>
        </div>
      )}

      <Offcanvas
        className="woffcanvas"
        show={show8}
        onHide={handleClose8}
        placement="top"
      >
        {" "}
        <Offcanvas.Header
          closeButton
          style={{ backgroundColor: "#F1F6F9" }}
        ></Offcanvas.Header>
        <Offcanvas.Body></Offcanvas.Body>
      </Offcanvas>
      <Footer />
    </>
  );
}

export default Items;
