import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Notification() {
  return (
    <>
    <Header />
    <div className="row mt-3 m-auto containerPadding">Notification</div>{" "} <Footer />
  </>
  )
}
export default  Notification