import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Reports() {
  return (
    <>
      <Header />
      <div className="row mt-3 m-auto containerPadding">Inventory</div>{" "} <Footer />
    </>
  );
}

export default Reports;
