import Logout from "./Component/Logout";
import Setting from "./Component/Setting";
import { Routes, Route, useLocation } from "react-router-dom";
import Overview from "./Component/Overview";
import "./App.css";
import Sidenav1 from "./Component/sidenav";
import { Login } from "./Component/Login";
import { Signup } from "./Component/Signup";
import Notification from "./Component/Notification";
import InventoryAdjustment from "./Component/InventoryAdjustment";
import Documents from "./Component/Documents";
import Purchase from "./Component/Purchase";
import Sales from "./Component/Sales";
import Reports from "./Component/Reports";
import Intigration from "./Component/Intigration";
import Dashboard from "./Component/Dashboard";
import Gettingstarted from "./Component/HomeGettingstarted";
import RecentUpdate from "./Component/RecentUpdate";
import Inventory from "./Component/Inventory";
import Items from "./Component/Items";
import ItemGroup from "./Component/ItemGroup";
import ItemCustompage from "./Component/ItemCustompage";
import NewItem from "./Component/NewItem";
import ItemDetails from "./Component/ItemDetails";
import Footer from "./Component/Footer";

export default function App() {
  const location = useLocation();
  const excludeRoutes = ["/RecceFile", "/"];
  const shouldRenderSidenav = !excludeRoutes.includes(location.pathname);

  return (
    <>
      <div className="App">
        {shouldRenderSidenav && (
          <div className="sidenav-container">
            <Sidenav1 />
          </div>
        )}
        <main>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/Overview" element={<Dashboard />} />
            <Route path="/Overview" element={<Overview />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route
              path="/InventoryAdjustment"
              element={<InventoryAdjustment />}
            />{" "}
            <Route path="/Items" element={<Items />} />
            <Route path="/ItemCustompage" element={<ItemCustompage />} />
            <Route path="/ItemGroup" element={<ItemGroup />} />
            <Route path="/Inventory" element={<Items />} />
            <Route path="/NewItem" element={<NewItem />} />
            <Route path="/ItemDetails" element={<ItemDetails />} />
            
            <Route path="/Documents" element={<Documents />} />
            <Route path="/Purchase" element={<Purchase />} />
            <Route path="/Sales" element={<Sales />} />
            <Route path="/Reports" element={<Reports />} />
            <Route path="/HomeGettingstarted" element={<Gettingstarted />} />
            <Route path="/RecentUpdate" element={<RecentUpdate />} />
            <Route path="/Intigration" element={<Intigration />} />
            <Route path="/Setting" element={<Setting />} />
            <Route path="/Logout" element={<Logout />} />
          </Routes>
        </main>
      </div>
    </>
  );
}
