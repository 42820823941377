import { React } from "react";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import Header from "./Header";
import Footer from "./Footer";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Overview() {
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <Header />
      <div className="row mt-3 m-auto containerPadding">
        <div className="row m-auto">
          <div className="col-md-4 ">
            <div className="row">
              <div className="col-md-2">
                <Button style={{ fontSize: "40px" }}>
                  <CorporateFareIcon
                    style={{ fontSize: "25px", backgroundColor: "#ECECEC" }}
                  />
                </Button>
              </div>{" "}
              <p className="col-md-4 ">
                <h6 className="m-auto">Hello,nnc.creation7</h6>{" "}
                <span className="fnt"> NNC</span>
              </p>
            </div>
          </div>
          <div className="col-md-8 ">
            <div className="row text-end">
              <div className="col-md-12 ">
                <p className="m-auto"> R.R.INDUSTRIES India Helpline: 18005726671</p>
                <div className="m-auto ">
                  <p className="m-auto fnt">
                    Mon - Fri • 9:00 AM - 7:00 PM • Toll Free
                  </p>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="row m-auto">
          <div className="col-md-2 ">
            <div className="row">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="/Dashboard"
              >
                <span className={path === "/Dashboard" ? "active2" : ""}>
                  {" "}
                  Dashboard
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-2 ">
            <div className="row ">
              <a
                style={{ textDecoration: "none", color: "black" }}
                href="/HomeGettingstarted"
              >
                <span
                  className={path === "/HomeGettingstarted" ? "active2" : ""}
                >
                  Getting Started
                </span>
              </a>
            </div>
          </div>
          <div className="col-md-2 ">
            <div className="row text-end">
              <a
                href="/RecentUpdate"
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className={path === "/RecentUpdate" ? "active2" : ""}>
                  Recent Update
                </span>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      
    </>
  );
}
