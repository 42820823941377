import React from "react";
import Header from "./Header";
import { Form } from "react-bootstrap";

function ItemDetails() {
  return (
    <>
      <Header />
      <div className="col-md-6">
        <Form.Check type="radio" />
        <Form.Label>Ceramic Mugs Set</Form.Label>
        <Form.Check type="radio" />
      </div>

      <Form.Check type="radio" />
      <div className="col-md-6"></div>
    </>
  );
}
export default ItemDetails;
