import { useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { NavLink } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/Row";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AppsIcon from "@mui/icons-material/Apps";
import SearchIcon from "@mui/icons-material/Search";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Modal from "react-bootstrap/Modal";
function Header() {
  const [showHeader, setshowHeader] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);

  const handleHeaderClose = () => setshowHeader(false);
  const handleHeaderShow = () => setshowHeader(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  // const useName = useSelector((state) => state.Name);

  const [selected, setSelected] = useState("All Items");
  const [showDropdown, setShowDropdown] = useState(false);

  const handleRowSelectedList = (e) => {
    const selectedItem = e.target.innerText.trim();
    setSelected(selectedItem);
    setShowDropdown(false);
  };

  const handleDropdownToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  return (
    <>
      <div className="header">
        <Navbar>
          <Nav className="me-auto">
            <Nav.Link>
              <AvTimerIcon />
            </Nav.Link>

            <div>
              <NavDropdown
                title={<SearchIcon />}
                show={showDropdown}
                onSelect={handleRowSelectedList}
                onToggle={handleDropdownToggle}
              >
                <div>
                  <ul
                    className="header_dropdown  p-0"
                    style={{ height: "40vh", overflowY: "auto" }}
                  >
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner"
                    >
                      All Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      {" "}
                      Inactive Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      {" "}
                      Un grouped Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>{" "}
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>{" "}
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>{" "}
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>{" "}
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      Low Stock Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner "
                    >
                      {" "}
                      Inactive Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner  "
                    >
                      {" "}
                      Un grouped Items
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList(e)}
                      className="col-md-12 list_inner  "
                    >
                      Low Stock Items
                    </li>
                  </ul>{" "}
                </div>
                <NavDropdown.Divider />
                <div onClick={handleHeaderShow}>
                  <NavDropdown.Item>
                    <SearchIcon
                      onClick={(e) => handleRowSelectedList(e)}
                      className="edit f_15"
                    />{" "}
                    <span onClick={(e) => handleRowSelectedList(e)}>
                      Search Advance
                    </span>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
            </div>
            <Offcanvas
              show={showHeader}
              onHide={handleHeaderClose}
              placement="top"
              className="shadow p-3 mb-5 bg-white rounded"
            >
              {" "}
              <div
                className="row"
                style={{
                  width: "96.9%",
                  backgroundColor: "#f9f9f9",
                  position: "absolute",
                  left: "1.4%",
                  top: "0%",
                  borderBottom: "1px solid grey",
                }}
              >
                <Offcanvas.Header closeButton>
                  <div className="col-md-8 ">
                    <span>Search</span>
                    <input />
                  </div>{" "}
                </Offcanvas.Header>
              </div>
              <Offcanvas.Body>
                <Form className="p-4">
                  <Row>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                    <Col className="mb-3">
                      {" "}
                      <Form.Label>Vendor Name </Form.Label>
                      <Form.Control />
                    </Col>
                  </Row>
                </Form>
              </Offcanvas.Body>
              <div
                className="row text-center"
                style={{
                  width: "99.9%",
                  backgroundColor: "#f9f9f9",
                  position: "absolute",
                  left: "1.4%",
                  bottom: "0%",
                  borderTop: "1px solid grey",
                }}
              >
                <div className="col-md-3 m-auto">
                  <Button className=" m-1 ">Search</Button>
                  <Button
                    style={{
                      backgroundColor: "#EEEDED",
                      border: "none",
                      color: "black",
                    }}
                    className=" m-1 "
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Offcanvas>
            <Nav.Link>
              <div className="text-center">
                <input
                  value={selected}
                  style={{
                    width: "300px",
                    height: "30px",
                    borderRadius: "6px",
                  }}
                />
              </div>{" "}
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link as={NavLink}>
              <AddBoxIcon className="btnclr" onClick={handleShow7} />
            </Nav.Link>

            <Nav.Link as={NavLink}>
              <PeopleOutlineIcon onClick={handleShow5} />
            </Nav.Link>
            <Nav.Link as={NavLink}>
              <NotificationsIcon onClick={handleShow3} />
            </Nav.Link>
            <Nav.Link as={NavLink}>
              <SettingsIcon onClick={handleShow6} />
            </Nav.Link>
            <Nav.Link as={NavLink}>
              {/* <span>
                {useName.fname[0]}
                <ExpandMoreIcon onClick={handleShow1}></ExpandMoreIcon>
              </span> */}
            </Nav.Link>

            <Nav.Link as={NavLink}>
              <AccountCircleIcon onClick={handleShow4} />
            </Nav.Link>
            <Nav.Link as={NavLink}>
              <AppsIcon onClick={handleShow2} />
            </Nav.Link>
          </Nav>
        </Navbar>
        <Offcanvas show={show1} onHide={handleClose1} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6"> My organization</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              <SettingsIcon className="m-auto" /> Manage{" "}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <CorporateFareIcon />{" "}
            <span className="fnt col-md-2">
              NNC Organization ID: 60022717692
            </span>
            <ContentCopyIcon className="col-md-2 m-1" />{" "}
            <span className="col-md-2 fnt">• Premium Trial</span>
            <CheckCircleIcon className="col-md-2 btnclr m-1" />
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Offcanvas show={show2} onHide={handleClose2} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6">All R.R.Industries Apps</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              <SettingsIcon className="m-auto" /> Manage{" "}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <p className="row gclr1">FINANCE APPS</p>
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Offcanvas show={show3} onHide={handleClose3} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6">All R.R.Industries Apps</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              {/* <SettingsIcon className="m-auto" /> Manage{" "} */}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <p className="row gclr1">FINANCE APPS</p> */}
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Offcanvas show={show4} onHide={handleClose4} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6">All R.R.Industries Apps</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              {/* <SettingsIcon className="m-auto" /> Manage{" "} */}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <p className="row gclr1">FINANCE APPS</p> */}
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Offcanvas show={show5} onHide={handleClose5} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6">All R.R.Industries Apps</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              {/* <SettingsIcon className="m-auto" /> Manage{" "} */}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <p className="row gclr1">FINANCE APPS</p> */}
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Offcanvas show={show6} onHide={handleClose6} placement="end">
          {" "}
          <Offcanvas.Header closeButton style={{ backgroundColor: "#F1F6F9" }}>
            <div className="col-md-6">All R.R.Industries Apps</div>
            <div className="col-md-5" style={{ color: "#4E4FEB" }}>
              {" "}
              {/* <SettingsIcon className="m-auto" /> Manage{" "} */}
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* <p className="row gclr1">FINANCE APPS</p> */}
          </Offcanvas.Body>
        </Offcanvas>{" "}
        <Modal className="addbox" show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose7}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose7}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Header;
