import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Documents() {
  return (
    <>
    <Header />
    <div className="row mt-3 m-auto containerPadding">Documents</div>{" "} <Footer />
  </>
  )
}

export default Documents