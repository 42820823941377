import React from "react";
import Overview from "./Overview";
import OverViewFooter from "./OverViewFooter";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import IosShareIcon from "@mui/icons-material/IosShare";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button";
import YouTubeIcon from "@mui/icons-material/YouTube";
function RecentUpdate() {
  return (
    <div>
      <Overview />
      <div className="row mt-3">
        <div className="col-md-6 m-auto">
          <div className="row d-flex">
            <div className="col-md-1 ">
              <FiberManualRecordIcon
                className="row m-auto"
                style={{ fontSize: "13px", color: "#068fff" }}
              />
              <p
                className="row track-line m-auto"
                style={{ position: "relative", bottom: "1.6%", left: "20%" }}
              ></p>
            </div>
            <div className="col-md-11">
              <p className="clr">23 June 2023</p>
              <h5>Regional Language SupportFeatured</h5>
              <p>
                Now, you can apply flat discounts before tax is applied on the
                tax-inclusive items in your sales and purchase transactions.
              </p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-1 ">
              <FiberManualRecordIcon
                className="row m-auto"
                style={{
                  fontSize: "13px",
                  color: "#068fff",
                  position: "relative",
                  bottom: "6.6%",
                }}
              />

              <p
                className="row track-line m-auto"
                style={{ position: "relative", bottom: "8.6%", left: "20%" }}
              ></p>
            </div>
            <div className="col-md-11">
              <p className="clr">23 June 2023</p>
              <h5>Regional Language SupportFeatured</h5>
              <p>
                Now, you can apply flat discounts before tax is applied on the
                tax-inclusive items in your sales and purchase transactions.
              </p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-1 ">
              <CircleOutlinedIcon
                className="row m-auto"
                style={{
                  fontSize: "10px",
                  position: "relative",
                  bottom: "11.9%",
                }}
              />
              <p
                className="row track-line track-line1 m-auto"
                style={{ position: "relative", bottom: "13.6%", left: "20%" }}
              ></p>
            </div>
            <div className="col-md-11">
              <p className="clr">23 June 2023</p>
              <h5>Regional Language SupportFeatured</h5>
              <p>
                You can now customise how the item name and description are
                displayed in a template. This feature is available in the
                following templates: Standard, Standard - Japanese Style,
                Standard - Japanese Style (Without Seal boxes), Standard - India
                GST Style, Standard - European Style, Spreadsheet, Spreadsheet -
                Plus, Spreadsheet - Lite, Spreadsheet - Compact, Minimalist,
                Grand, Continental, Lite, Simple, Compact, Basic, Retail -
                Standard, Retail - Premium, Business Letter and Elegant.
              </p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="col-md-1 ">
              <CircleOutlinedIcon
                className="row m-auto"
                style={{
                  fontSize: "10px",
                  position: "relative",
                  bottom: "23.6%",
                }}
              />
              <p
                className="row track-line m-auto"
                style={{ position: "relative", bottom: "24.9%", left: "20%" }}
              ></p>
            </div>
            <div className="col-md-11">
              <p className="clr">23 June 2023</p>
              <h5>Regional Language SupportFeatured</h5>
              <p>
                Now, you can apply flat discounts before tax is applied on the
                tax-inclusive items in your sales and purchase transactions.
              </p>
            </div>
          </div>
          <div className="row text-center  p-5">
            {" "}
            <a href="#" className="col-md-5 m-auto">
              More Updates <ArrowRightAltIcon />
            </a>
          </div>
        </div>
        <div className="col-md-3 m-auto p-2">
          <div className="row ">
            <Card>
              <img
                className="row"
                src="https://d31g2a6snus4ly.cloudfront.net/zbooks/assets/images/helpdoc-banner-c625e7c37a.png"
                alt=""
              />
              <div className="row">
                <div className="col-md-2">
                  <BookOnlineIcon />
                </div>
                <div className="col-md-8">
                  {" "}
                  <a>HELP RESOURCES</a>
                </div>
              </div>
              <div className="row">
                <h6>Want to understand how R.R.Industries works?</h6>
              </div>
              <div className="row">
                <p>
                  Read our help resources to understand R.R.Industries in-depth
                  and how you can make the most of the features.
                </p>
              </div>
              <div className="row  p-2">
                <div className="col-md-8">
                  <div className="row">
                    <Button className="col-md-9">
                      <span className="f_12">Visit Help Doc</span>
                    </Button>
                    <a href="#" className="col-md-2">
                      <IosShareIcon className="icon f_14" />
                    </a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <a href="#" className="col-md-7 anchore_Tag">
                      {" "}
                      <span className="f_12"> FAQ</span>
                    </a>
                    <a href="#" className="col-md-2 anchore_Tag">
                      <IosShareIcon className="icon f_14" />
                    </a>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="row mt-5 p-2">
            <Card>
              <div className="row">
                <p>
                  <YouTubeIcon />
                  VIDEO TUTORIALS
                </p>
              </div>
              <div className="row">
                <p>
                  Visit our YouTube channel and watch the videos and webinars to
                  learn everything about R.R.Industries.
                </p>
              </div>
              <div className="row p-2">
                <div className="row">
                  <Button className="col-md-9 m-auto ">
                    <span className="f_12"> Go To YouTube Channel </span>
                  </Button>

                  <a href="#" className="col-md-2 m-auto">
                    <IosShareIcon className="icon f_20" />
                  </a>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <OverViewFooter />
    </div>
  );
}

export default RecentUpdate;
