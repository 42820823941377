import { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import CloseIcon from "@mui/icons-material/Close";
import Form from "react-bootstrap/Form";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Creteria from "./Creteria";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import SearchIcon from "@mui/icons-material/Search";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
function ItemCustompage() {
  const [show10, setShow10] = useState(false);
  const handleClose10 = () => setShow10(false);
  const handleShow10 = () => setShow10(true);
  const [Favorite, setFavorite] = useState(false);
  const [criteriaList, setCriteriaList] = useState([]);
  const [selected1, setSelected1] = useState([]);

  const handleAddCreteria = () => {
    setCriteriaList([...criteriaList, { id: Date.now() }]);
  };

  const handleRemoveCreteria = (id) => {
    setCriteriaList((prevCriteriaList) =>
      prevCriteriaList.filter((criterion) => criterion.id !== id)
    );
  };

  const handleRowSelectedList = (e) => {
    e.preventDefault();
    const selectedOption = e.currentTarget.textContent;
    setSelected1((prevValue) => [...prevValue, selectedOption]);
  };

  const handleRemoveColumns = (id) => {
    setSelected1((prevValue) =>
      prevValue.filter((columan) => columan.id !== id)
    );
  };

  useEffect(() => {}, [selected1]);
  return (
    <>
      <Header />
      <div className="row shadow-sm p-3  bg-white customheader ">
        <div className="col-md-4 m-auto ">
          <h5>New Custom View</h5>
        </div>
        <div className="col-md-7 ">
          <Link
            to="/Inventory"
            className="m-auto float-end itemadd"
            style={{ color: "black" }}
          >
            <CloseIcon onClick={handleShow10} />
          </Link>
        </div>
      </div>
      
      <div className="row  bg-light p-5 m-auto ">
        <Form.Label>
          Name<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <div className="col-md-5 ">
          <Form.Control className="hcard" />
        </div>
        <div className="col-md-6">
          <div className="m-auto ">
            {!Favorite ? (
              <span className="col-md-6">
                <StarBorderIcon onClick={() => setFavorite(true)} />
              </span>
            ) : (
              <span>
                <StarIcon
                  onClick={() => setFavorite(false)}
                  style={{ color: "orange" }}
                />
              </span>
            )}
            <span className="fnt_15">Marks as Favorite</span>
          </div>
        </div>
      </div>
      <div className="row m-2">
        <div className="row mt-5">
          <div className="row ">
            <p>Define the criteria ( if any )</p>
          </div>
          <div className="row ">
            <Creteria />
            {criteriaList.map((criterion, index) => (
              <Creteria
                key={criterion.id}
                index={index}
                onDelete={() => handleRemoveCreteria(criterion.id)}
              />
            ))}
          </div>
        </div>{" "}
        <div className="row mt-3">
          <p className="col-md-4 " onClick={handleAddCreteria}>
            <AddCircleIcon className="btnclr " />
            <span>Add Creteria</span>{" "}
          </p>
        </div>
        <div className="row m-1">
          <div className="col-md-10">
            <div
              className="row shadow-sm  p-2 rounded bg-light text-center"
              style={{ border: "1px solid grey" }}
            >
              <div className="col-md-3 m-auto">
                <p className="m-auto">CRETERIA PATTERN</p>{" "}
              </div>
              <div className="col-md-4  m-auto">
                {" "}
                ( ( ( ( 1 ) ) OR 2 ) AND 3 )
              </div>
              <div className="col-md-5  text-end">
                {" "}
                <ModeEditIcon className="m-auto edit" />
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="row mt-3">
          <p>Columns Preference:</p>
        </div>
        <div className="row">
          <div className="col-md-4">AVAILABLE COLUMNS</div>
          <div className="col-md-6">
            <VerifiedIcon style={{ color: "green" }} />
            SELECTED COLUMNS
          </div>
        </div>
        <div className="col-md-8">
          <div className="row m-2   border rounded">
            <div className="col-md-6">
              <div className="row">
                <InputGroup>
                  <InputGroup.Text className="row">
                    <span>
                      {" "}
                      <SearchIcon />
                    </span>
                  </InputGroup.Text>
                  <Form.Control className="row" />
                </InputGroup>{" "}
              </div>
              <div className="row">
                <ul className="custom_list ">
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> All Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> Inactive
                      Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" />
                      Un grouped Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" />
                      Un grouped Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> Inactive
                      Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> Low Stock
                      Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> Inactive
                      Items
                    </div>{" "}
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row p-1 list_inner1"
                  >
                    <div className="col-md-6">
                      <DragIndicatorIcon className="fnt_15 fnt" /> Low Stock
                      Items
                    </div>
                    <div className="col-md-6">
                      <AddCircleOutlineIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end itemadd"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 left">
              <div className="row new_list ">
                {selected1 !== "" &&
                  selected1.map((ele, id) => {
                    if (ele.trim() !== "") {
                      return (
                        <li className="row p-1 list_inner1" key={id}>
                          <div className="col-md-6">
                            <DragIndicatorIcon className="fnt_15 fnt" />
                            {ele}
                          </div>
                          <div className="col-md-6">
                            <RemoveCircleIcon
                              onClick={() => handleRemoveColumns(id)}
                              style={{ color: "red" }}
                              className="float-end itemadd"
                            />
                          </div>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <p>Visibility Preference</p>
        </div>
        <div className="row  bottom mb-3">
          <span className="col-md-2"> Make this visible to:</span>
          <div className="col-md-3 m-auto">
            <div className="row m-auto">
              <Form.Check inline type="radio" label="Only me" name="group" />
            </div>
          </div>
          <div className="col-md-4 m-auto">
            <div className="row m-auto">
              <Form.Check
                inline
                type="radio"
                label="Only Selected Users & Roles"
                name="group"
              />
            </div>
          </div>
          <div className="col-md-3 m-auto">
            <div className="row m-auto">
              <Form.Check inline type="radio" label="everyone" name="group" />
            </div>
          </div>
        </div>
        <div className="row m-5">
          <Button className="col-md-1 m-2">Save</Button>
          <Button className="col-md-1 m-2" variant="light">
            Cancel
          </Button>
        </div>
      </div>

      <Modal show={show10} onHide={handleClose10}>
        <Modal.Header closeButton>
          <Modal.Title>Leave this page?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          If you leave, your unsaved changes will be discarded.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose10}>
            Stay here
          </Button>
          <Button variant="light" onClick={handleClose10}>
            Leave & discard changes
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  );
}

export default ItemCustompage;
