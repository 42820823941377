import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function Intigration() {
  return (
    <>
      <Header />
      <div className="row mt-3 m-auto containerPadding">Intigration</div>{" "}
      <Footer />
    </>
  );
}

export default Intigration;
