import React from "react";
import Header from "./Header";
import Footer from "./Footer";

function ItemGroup() {
  return (
    <>
      <Header />
      <h>ITems Groups</h>
      <Footer />
    </>
  );
}

export default ItemGroup;
