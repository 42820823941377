import { useState } from "react";
import Form from "react-bootstrap/Form";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

export default function Creteria({ index, onDelete }) {
  const [selected1, setSelected1] = useState("All Items");
  const [showDropdown1, setShowDropdown1] = useState(false);

  const handleRowSelectedList = (e) => {
    const selectedOption = e.target.textContent;
    setSelected1(selectedOption);
    setShowDropdown1(false);
  };

  const handleDropdownToggle = () => {
    setShowDropdown1((prevShowDropdown1) => !prevShowDropdown1);
  };

  return (
    <>
      <div className="col-md-3">
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">{index + 1}</InputGroup.Text>
          <Form.Control value={selected1} />
          <InputGroup.Text>
            <KeyboardArrowDownIcon
              show={showDropdown1}
              onSelect={handleRowSelectedList}
              onClick={handleDropdownToggle}
            />
          </InputGroup.Text>
        </InputGroup>

        {showDropdown1 && (
          <ul className="custom_list shadow-sm p-3  bg-white rounded">
            <Form.Control />
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              All Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Inactive Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Un grouped Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Low Stock Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Low Stock Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Low Stock Items
            </li>

            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Low Stock Items
            </li>
            <li
              onClick={handleRowSelectedList}
              className="col-md-11 p-1 list_inner"
            >
              Low Stock Items
            </li>
          </ul>
        )}
      </div>
      <div className="col-md-3">
        <Form.Select>
          <option>Select Comparison</option>
          <option>SKU</option>
          <option>Rate</option>
        </Form.Select>
      </div>
      <div className="col-md-4">
        <Form.Control />
      </div>
      <div className="col-md-1">
        <div className="row">
          <div className="col-md-6">
            <OverlayTrigger
              placement="top"
              trigger="hover"
              overlay={<Tooltip>Remove Creterion</Tooltip>}
            >
              <DeleteIcon onClick={onDelete} />
            </OverlayTrigger>
          </div>
        </div>
      </div>

      <div className="row m-1">
        <select className="col-md-1" style={{ padding: "5px" }}>
          <option>AND</option>
          <option>OR</option>
        </select>
      </div>
    </>
  );
}
