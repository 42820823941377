import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import Overview from "./Overview";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
export const Login = () => {
  const UserNumber = useSelector((state) => state.Name);
  const [Visibility, setVisibility] = useState(false);

  const handleVisibility = () => {
    setVisibility(true);
  };
  const handleVisibilityof = () => {
    setVisibility(false);
  };
  return (
    <>
      <div className="row  m-auto mt-5">
        <Form className="col-md-8  m-auto ">
          <div className="row shadow-sm   bg-white rounded">
            <div className="col-md-6  ">
              <Row className="mb-3  text-center">
                <h6 className="col-md-4 m-auto">Login</h6>{" "}
              </Row>
              <Row>
                {/* <img
                  className="col-md-4 m-auto"
                  width={"60px"}
                  height={"20px"}
                  src="..\Assests\Gauge_16-removebg-preview.png"
                  alt=""
                // /> */}
              </Row>
              <Row className="mb-3  m-auto">
                <Form.Label>
                  Username <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control type="text" placeholder="user name" />
              </Row>
              {Visibility ? (
                <>
                  <Row
                    className="mb-3  m-auto"
                    style={{ position: "relative" }}
                  >
                    {" "}
                    <Form.Label>
                      Password <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Password " />
                  </Row>
                  <div
                    style={{
                      position: "relative",
                      bottom: "12%",
                      right: "-88%",
                    }}
                  >
                    <VisibilityIcon onClick={handleVisibilityof} />
                  </div>
                </>
              ) : (
                <>
                  <Row className="mb-3  m-auto">
                    {" "}
                    <Form.Label>
                      Password <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Password " />
                  </Row>
                  <div
                    style={{
                      position: "relative",
                      bottom: "12%",
                      right: "-88%",
                    }}
                  >
                    <VisibilityOffIcon onClick={handleVisibility} />
                  </div>
                </>
              )}

              <Row className="mb-3 mt-3">
                <Button variant="light" href="/Overview">
                  Login
                </Button>{" "}
              </Row>
              <Row className="mb-3 mt-2">
                <Link to="/Signup">
                  <p className="m-2">If your new to R.R.Industries? create account</p>
                </Link>
              </Row>
            </div>
            <img
              alt=""
              className="col-md-6 m-auto"
              style={{ padding: "0px" }}
              src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/industry-logo-icon-design-template-5feb95a8a8ece3983361fd8d6ad3111f_screen.jpg?ts=1586698576"
            />{" "}
          </div>
        </Form>
      </div>
    </>
  );
};
