import { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import InputGroup from "react-bootstrap/InputGroup";
import HighlightAltOutlinedIcon from "@mui/icons-material/HighlightAltOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Button, Card } from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import { ButtonBase } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CloseIcon from "@mui/icons-material/Close";
function NewItem() {
  const [selected1, setSelected1] = useState("All Items");
  const [Dimensions, setDimensions] = useState();
  const [showbrand, setShowBrand] = useState(false);
  const [brand, setBrand] = useState("cm");
  const [showmanufacture, setShowmanuFacture] = useState(false);
  const [Manufacturer, setManufacturer] = useState("cm");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [Weight, setWeight] = useState("kg");
  const [manufacturManage, setManufacturManage] = useState(false);
  const [AddManufactureName, setAddManufactureName] = useState(false);
  const [brandManage, setbrandManage] = useState(false);
  const [AddBrandName, setAddBrandName] = useState(false);
  const handleManfatureShow = () => setManufacturManage(true);
  const handleManfatureClose = () => setManufacturManage(false);

  const handleManfature = (e) => {
    const item = e.target.textContent;
    setManufacturer(item);
    setShowmanuFacture(false);
  };

  const handleManfatureDropdown = () => {
    setShowmanuFacture((prevShowDropdown2) => !prevShowDropdown2);
  };

  const handlebrand = (e) => {
    const brand = e.target.textContent;
    setBrand(brand);
    setShowBrand(false);
  };

  const handletogglebrand = () => {
    setShowBrand((prev) => !prev);
  };

  const handleRowSelectedList2 = (e) => {
    const selectedOption2 = e.target.textContent;
    setWeight(selectedOption2);
    setShowDropdown2(false);
  };

  const handleDropdownToggle2 = () => {
    setShowDropdown2((prevShowDropdown2) => !prevShowDropdown2);
  };

  const handleRowSelectedList = (e) => {
    const selectedOption = e.target.textContent;
    setSelected1(selectedOption);
    setShowDropdown(false);
  };

  const handleDropdownToggle = () => {
    setShowDropdown((prevShowDropdown1) => !prevShowDropdown1);
  };
  const handleRowSelectedList1 = (e) => {
    const selectedOption1 = e.target.textContent;
    setDimensions(selectedOption1);
    setShowDropdown1(false);
  };

  const handleDropdownToggle1 = () => {
    setShowDropdown1((prevShowDropdown1) => !prevShowDropdown1);
  };
  return (
    <>
      <Header />
      <div className="row shadow-sm p-3  bg-white  customheader ">
        <div className="col-md-4 m-auto ">
          <h5>New Item</h5>
        </div>
        <div className="col-md-7 ">
          <Link
            to="/Inventory"
            className="m-auto float-end itemadd1"
            style={{ color: "black" }}
          >
            <CloseIcon />
          </Link>
        </div>
      </div>
      <div className="row  bg-light p-5 m-auto ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-3">
              <p>
                <span> Type</span>
                <span className="m-auto">
                  <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                </span>
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <Form.Check
                  inline
                  type="radio"
                  label="Goods"
                  name="group"
                  className="col-md-2"
                />

                <Form.Check
                  inline
                  type="radio"
                  label="Service"
                  name="group"
                  className="col-md-2"
                />
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-3">
              <p style={{ color: "red" }}>Name*</p>
            </div>
            <div className="col-md-9">
              <div className="row mb-2">
                <Form.Control />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <p>
                <span className="m-1"> SKU</span>

                <span className="m-auto">
                  <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                </span>
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <Form.Control className="mb-2" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <p>
                <span style={{ color: "red" }}>Unit*</span>
                <span className="m-auto">
                  <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                </span>
              </p>
            </div>
            <div className="col-md-9" style={{ position: "relative" }}>
              <InputGroup>
                <Form.Control value={selected1} className="row" />
                <InputGroup.Text className="row" onClick={handleDropdownToggle}>
                  <span>
                    {" "}
                    <KeyboardArrowDownIcon />
                  </span>
                </InputGroup.Text>
              </InputGroup>
              <div className="row mt-2">
                <Form.Check className="col-md-1" />{" "}
                <span className="col-md-5 ">Returnable Item </span>{" "}
                <span className="col-md-1 m-0 p-0">
                  {" "}
                  <HelpOutlineOutlinedIcon className=" fnt fnt_15" />
                </span>
              </div>
              {showDropdown && (
                <ul
                  className=" shadow-none p-3 mb-5 bg-white "
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "56%",
                    left: 0,
                    zIndex: 10,
                  }}
                >
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1"
                  >
                    <div className="col-md-6">All Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>

                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1 "
                  >
                    <div className="col-md-6">Inactive Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1 "
                  >
                    <div className="col-md-6">Un grouped Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1 "
                  >
                    {" "}
                    <div className="col-md-6"> Low Stock Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>

                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1 "
                  >
                    {" "}
                    <div className="col-md-6">Inactive Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1"
                  >
                    <div className="col-md-6"> Un grouped Items </div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList(e)}
                    className="row list_inner1"
                  >
                    <div className="col-md-6">Low Stock Items</div>
                    <div className="col-md-6">
                      <DeleteOutlineOutlinedIcon
                        onClick={(e) => handleRowSelectedList(e)}
                        className="float-end fnt_15 itemadd itemadd1"
                      />
                    </div>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 m-auto">
          <div className="row  m-auto">
            <div className="row">
              <div
                className="col-md-5 m-auto p-3"
                style={{
                  borderStyle: "dashed",
                  borderWidth: "thin",
                  color: "gray",
                }}
              >
                <div className="text-center">
                  <Form.Label>
                    <HighlightAltOutlinedIcon
                      className="fnt m-auto "
                      style={{ fontSize: "40px" }}
                    />

                    <Form.Control
                      type="file"
                      className="m-auto "
                      style={{ display: "none" }}
                    />
                  </Form.Label>
                </div>
                <div className="row">
                  <div className="m-auto">
                    <p style={{ fontSize: "12px" }}>drag images here or</p>
                    <a
                      style={{ fontSize: "12px" }}
                      href="/https://www.istockphoto.com/search/2/image?phrase=ceramic+material"
                    >
                      {" "}
                      Browse images
                    </a>
                    <p style={{ fontSize: "12px" }}>
                      You can add up to 15 images, each not exceeding 5 MB.
                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row  bottom  p-5  ">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-3">
              <p className="row">
                <span className="col-md-4 ">Dimensions</span>
                <span className="col-md-12 gclr1">
                  (Length X Width X Height)
                </span>
              </p>
            </div>
            <div className="col-md-9" style={{ position: "relative" }}>
              <InputGroup>
                <Form.Control className="row" />
                <InputGroup.Text
                  className="row"
                  onClick={handleDropdownToggle1}
                >
                  <span>
                    {Dimensions}
                    <KeyboardArrowDownIcon />
                  </span>
                </InputGroup.Text>
              </InputGroup>

              {showDropdown1 && (
                <ul
                  className=" shadow p-3 mb-5 bg-white rounded "
                  style={{
                    width: "16%",
                    position: "absolute",
                    top: "36%",
                    right: 0,
                    zIndex: 10,
                  }}
                >
                  <li
                    onClick={(e) => handleRowSelectedList1(e)}
                    className="row list_inner1"
                  >
                    <span>cm</span>
                  </li>

                  <li
                    onClick={(e) => handleRowSelectedList1(e)}
                    className="row list_inner1 "
                  >
                    <span>cm</span>
                  </li>

                  <li
                    onClick={(e) => handleRowSelectedList1(e)}
                    className="row list_inner1"
                  >
                    <span>cm</span>
                  </li>
                  <li
                    onClick={(e) => handleRowSelectedList1(e)}
                    className="row list_inner1"
                  >
                    <span>cm</span>
                  </li>
                </ul>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <p className="row">
                <span className="col-md-4 ">Manufacturer</span>
              </p>
            </div>
            <div className="col-md-9" style={{ position: "relative" }}>
              <InputGroup>
                <Form.Control className="row" />
                <InputGroup.Text
                  className="row"
                  onClick={handleManfatureDropdown}
                >
                  <span>
                    {Manufacturer}
                    <KeyboardArrowDownIcon />
                  </span>
                </InputGroup.Text>
              </InputGroup>

              {showmanufacture && (
                <div
                  className="border"
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    zIndex: 10,
                  }}
                >
                  <div className="shadow p-3 bottom  bg-white ">
                    <Form.Control className="row" placeholder="Search" />
                    <ul className="row">
                      {/* <li
  onClick={(e) => handleManfature(e)}
  className="row list_inner1"
>
  <span>{}</span>
</li> */}
                    </ul>
                    <div className="row  ">Result not Found</div>
                  </div>
                  <div className="  bg-light ">
                    <Button
                      className="edit"
                      variant="light "
                      onClick={handleManfatureShow}
                    >
                      <SettingsIcon className="fnt_15 edit" />{" "}
                      <span className="fnt_15 edit">Manage Manufacturer</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <p>
                <span className="m-1"> UPC</span>

                <span className="m-auto">
                  <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                </span>
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <Form.Control className="mb-2" />
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <p>
                <span className="m-1"> EAN </span>

                <span className="m-auto">
                  <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                </span>
              </p>
            </div>
            <div className="col-md-9">
              <div className="row">
                <Form.Control className="mb-2" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="row">
            <div className="row m-2">
              <div className="col-md-3">
                <p className="row">
                  <span className="col-md-4 ">Weight</span>
                </p>
              </div>
              <div className="col-md-9" style={{ position: "relative" }}>
                <InputGroup>
                  <Form.Control className="row" />
                  <InputGroup.Text
                    className="row"
                    onClick={handleDropdownToggle2}
                  >
                    <span>
                      {Weight}
                      <KeyboardArrowDownIcon />
                    </span>
                  </InputGroup.Text>
                </InputGroup>

                {showDropdown2 && (
                  <ul
                    className=" shadow p-3 mb-5 bg-white rounded "
                    style={{
                      width: "16%",
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      zIndex: 10,
                    }}
                  >
                    <li
                      onClick={(e) => handleRowSelectedList2(e)}
                      className="row list_inner1"
                    >
                      <span>kg</span>
                    </li>

                    <li
                      onClick={(e) => handleRowSelectedList2(e)}
                      className="row list_inner1 "
                    >
                      <span>k</span>
                    </li>

                    <li
                      onClick={(e) => handleRowSelectedList2(e)}
                      className="row list_inner1"
                    >
                      <span>oz</span>
                    </li>
                    <li
                      onClick={(e) => handleRowSelectedList2(e)}
                      className="row list_inner1"
                    >
                      <span>cm</span>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <div className="row m-2">
              <div className="col-md-3">
                <p className="row">
                  <span className="col-md-4 ">Brand</span>
                </p>
              </div>
              <div className="col-md-9" style={{ position: "relative" }}>
                <InputGroup>
                  <Form.Control className="row" />
                  <InputGroup.Text className="row" onClick={handletogglebrand}>
                    <span>
                      {brand}
                      <KeyboardArrowDownIcon />
                    </span>
                  </InputGroup.Text>
                </InputGroup>

                {showbrand && (
                  <div
                    className="border"
                    style={{
                      width: "100%",
                      position: "absolute",
                      top: "100%",
                      right: 0,
                      zIndex: 10,
                    }}
                  >
                    <div className="shadow p-3 bottom  bg-white ">
                      <Form.Control className="row" placeholder="Search" />
                      <ul className="row">
                        {/* <li
                      onClick={(e) => handleManfature(e)}
                      className="row list_inner1"
                    >
                      <span>{}</span>
                    </li> */}
                      </ul>
                      <div className="row  ">Result not Found</div>
                    </div>
                    <div className="  bg-light ">
                      <Button
                        className="edit"
                        variant="light "
                        onClick={() => setbrandManage(true)}
                      >
                        <SettingsIcon className="fnt_15 edit" />{" "}
                        <span className="fnt_15 edit">Manage Brand</span>
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row m-2">
              <div className="col-md-3">
                <p>
                  <span className="m-1"> MPN </span>

                  <span className="m-auto">
                    <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                  </span>
                </p>
              </div>
              <div className="col-md-9 p-0">
                <Form.Control className="row m-auto" />
              </div>
            </div>
            <div className="row m-2">
              <div className="col-md-3">
                <p>
                  <span className="m-1"> ISBN </span>

                  <span className="m-auto">
                    <HelpOutlineOutlinedIcon className="fnt fnt_15" />
                  </span>
                </p>
              </div>
              <div className="col-md-9 p-0">
                <Form.Control className="row m-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="row m-2 ">
        <Button className="col-md-1 m-2">Save</Button>
        <Button className="col-md-1 m-2" variant="light">
          Cancel
        </Button>
      </div>
      <Modal
        className="manufacturemodel"
        show={manufacturManage}
        onHide={handleManfatureClose}
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title className="fnt_15">Manage Manufacturers</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bottom">
          {!AddManufactureName ? (
            <Button className="row" onClick={() => setAddManufactureName(true)}>
              <span className="col-md-3">
                <AddOutlinedIcon />
              </span>
              <span className="col-md-4 text-center">New Manufacturer</span>
            </Button>
          ) : (
            <div className="row ">
              <Card className="col-md-10 m-auto p-2 bg-light">
                <Form.Label style={{ color: "red" }}>
                  Manufacturer Name*
                </Form.Label>
                <Form.Control className="col-md-4 " />

                <div className="row m-3">
                  <div className="col-md-6">
                    <Button onClick={() => setAddManufactureName(false)}>
                      Save and Select
                    </Button>
                  </div>
                  <div className="col-md-3">
                    <Button variant="light">Cancel</Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Modal.Body>
        <Modal.Body>
          <div className="row  bottom">
            <p className="col-md-3">MANUFACTURERS</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="manufacturemodel"
        show={brandManage}
        onHide={() => setbrandManage(false)}
      >
        <Modal.Header className="bg-light" closeButton>
          <Modal.Title className="fnt_15">Manage Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bottom">
          {!AddBrandName ? (
            <Button className="row" onClick={() => setAddBrandName(true)}>
              <span className="col-md-3">
                <AddOutlinedIcon />
              </span>
              <span className="col-md-4 text-center">New Brand</span>
            </Button>
          ) : (
            <div className="row ">
              <Card className="col-md-10 m-auto p-2 bg-light">
                <Form.Label style={{ color: "red" }}>Brand Name*</Form.Label>
                <Form.Control className="col-md-4 " />

                <div className="row m-3">
                  <div className="col-md-6">
                    <Button onClick={() => setAddBrandName(false)}>
                      Save and Select
                    </Button>
                  </div>
                  <div className="col-md-3">
                    <Button variant="light">Cancel</Button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </Modal.Body>
        <Modal.Body>
          <div className="row  bottom">
            <p className="col-md-3">Brand</p>
          </div>
        </Modal.Body>
      </Modal>
      <div className="mb-5"></div>
      <Footer />
    </>
  );
}

export default NewItem;
