import React, { useEffect } from "react";
import Overview from "./Overview";
import OverViewFooter from "./OverViewFooter";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { Button, Card } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Footer from "./Footer";

function Gettingstarted() {
  return (
    <>
      <Overview />
      <div className="row b_clr p-5 mt-3">
        <div className="row text-center">
          <div className="col-md-6 m-auto">
            <span>Welcome to R.R.Industries </span>{" "}
            <span className=" left btnclr">
              <PlayCircleOutlineIcon />
              Overview of R.R.Industries R.R.Industries
            </span>
          </div>
        </div>
        <div className="row text-center">
          <p className="fnt">
            R.R.Industries is your complete online R.R.Industries management solution. Let us
            help you set up R.R.Industries R.R.Industries and manage your stocks effectively.
          </p>
        </div>

        <div className="col-md-12">
          <div className="row m-auto container_sty">
            <Card className="col-md-5 m-2 hcard p-3  ">
              <div className="row ">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  <img
                    className="m-1"
                    width={"60px"}
                    height={"20px"}
                    src="..\Assests\Gauge_16-removebg-preview.png"
                    alt=""
                  />
                  <BusinessCenterIcon className="m-1" />
                  <span className="m-1">Bisty</span>
                  <span className="m-1">learn More...</span>
                </Button>{" "}
              </div>

              <div className="row m-3">
                <Button
                  className="col-md-2 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    textTransform: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                  }}
                >
                  Shet up
                </Button>
              </div>
            </Card>
            <Card className="col-md-5 m-2 hcard p-3">
              <div className="row">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  <img
                    className="m-1"
                    width={"60px"}
                    height={"20px"}
                    src="..\Assests\Gauge_16-removebg-preview.png"
                    alt=""
                  />
                  <BusinessCenterIcon className="m-1" />
                  <span className="m-1">Bisty</span>
                  <span className="m-1">learn More...</span>
                </Button>
              </div>
              <div className="row m-3">
                <Button
                  className="col-md-2 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    textTransform: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                  }}
                >
                  Shet up
                </Button>
              </div>
            </Card>
            <Card className="col-md-5 m-2 hcard p-3">
              <div className="row">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>{" "}
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  <img
                    className="m-1"
                    width={"60px"}
                    height={"20px"}
                    src="..\Assests\Gauge_16-removebg-preview.png"
                    alt=""
                  />
                  <BusinessCenterIcon className="m-1" />
                  <span className="m-1">Bisty</span>
                  <span className="m-1">learn More...</span>
                </Button>
              </div>
              <div className="row m-3">
                <Button
                  className="col-md-2 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    textTransform: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                  }}
                >
                  Shet up
                </Button>
              </div>
            </Card>
            <Card className="col-md-5 m-2 hcard p-3">
              <div className="row">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  <img
                    className="m-1"
                    width={"60px"}
                    height={"20px"}
                    src="..\Assests\Gauge_16-removebg-preview.png"
                    alt=""
                  />
                  <BusinessCenterIcon className="m-1" />
                  <span className="m-1">Bisty</span>
                  <span className="m-1">learn More...</span>
                </Button>
              </div>
              <div className="row m-3">
                <Button
                  className="col-md-4 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                    textTransform: "none",
                  }}
                >
                  Configure
                </Button>
              </div>
            </Card>
            <Card className="col-md-5 m-2 hcard p-3">
              <div className="row">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-6">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                  }}
                >
                  Configure
                </Button>
              </div>
              <div className="row m-3">
                <Button
                  className="col-md-2 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    textTransform: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                  }}
                >
                  Shet up
                </Button>
              </div>
            </Card>
            <Card className="col-md-5 m-2 hcard p-3">
              <div className="row">
                <div className="col-md-2">
                  <div className="c_icon">
                    <ShoppingCartIcon className="c_icon1" />
                  </div>
                </div>
                <div className="col-md-10">
                  <h6>Sales Channels</h6>
                </div>
              </div>
              <div className="row ">
                <p className="col-md-8 fnt m-auto">
                  R.R.Industries supports native integrations with shopping carts and
                  marketplaces like Amazon, eBay, Etsy, and Shopify, enabling
                  you to manage your R.R.Industries more efficiently while selling from
                  sales channels.
                  <a href="#" style={{ textDecoration: "none" }}>
                    {" "}
                    Learn more
                  </a>
                </p>
              </div>
              <div className="row m-3">
                <Button
                  style={{
                    backgroundColor: "#EEEDED",
                    border: "none",
                    color: "black",
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className="row m-3">
                <Button
                  className="col-md-2 text-center fnt hr"
                  style={{
                    backgroundColor: "#068fff31",
                    border: "none",
                    textTransform: "none",
                    padding: "0px",
                    borderRadius: "100px",
                    color: "black",
                  }}
                >
                  Shet up
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="row p-5 ">
        <div className="col-md-3 ">
          <div className="row">
            <div className="col-md-2">
              <EmailIcon className="c_icon" />
            </div>
            <div className="col-md-10">
              <div className="row">
                <h6>Have a question?</h6>
              </div>
              <div className="row">
                <span className="fnt">Write to us at</span>
              </div>
              <div className="row">
                <a
                  href="#"
                  className="anchore_Tag"
                  style={{ wordWrap: "break-word" }}
                >
                  support.india@R.R.Industries.com{" "}
                </a>
                <span>and we'll answer you.</span>
              </div>
              <div className="row">
                <a href="#" className="anchore_Tag">
                  Mail us <ArrowRightAltIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 left">
          <div className="row">
            <div className="col-md-2">
              <EmailIcon className="c_icon" />
            </div>
            <div className="col-md-10">
              <div className="row">
                <h6>Have a question?</h6>
              </div>
              <div className="row">
                <span className="fnt">Write to us at</span>
              </div>
              <div className="row">
                <a
                  href="#"
                  className="anchore_Tag"
                  style={{ wordWrap: "break-word" }}
                >
                  support.india@R.R.Industries.com{" "}
                </a>
                <span>and we'll answer you.</span>
              </div>
              <div className="row">
                <a href="#" className="anchore_Tag">
                  Mail us <ArrowRightAltIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 left">
          <div className="row">
            <div className="col-md-2">
              <EmailIcon className="c_icon" />
            </div>
            <div className="col-md-10">
              <div className="row">
                <h6>Have a question?</h6>
              </div>
              <div className="row">
                <span className="fnt">Write to us at</span>
              </div>
              <div className="row">
                <a
                  href="#"
                  className="anchore_Tag"
                  style={{ wordWrap: "break-word" }}
                >
                  support.india@R.R.Industries.com{" "}
                </a>
                <span>and we'll answer you.</span>
              </div>
              <div className="row">
                <a href="#" className="anchore_Tag">
                  Mail us <ArrowRightAltIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 left text-center m-auto">
          <div className="row  ">
            <p>Follow us and stay up to date</p>
          </div>
          <div className="row m-auto">
            <div className="col-md-2">
              <InstagramIcon />
            </div>
            <div className="col-md-2">
              <TwitterIcon />
            </div>
            <div className="col-md-2">
              <YouTubeIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="row  m-auto mt-5">
        <OverViewFooter />
      </div>{" "}
      <Footer />
    </>
  );
}

export default Gettingstarted;
