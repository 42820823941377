import React from "react";
import Header from "./Header";

export default function Setting() {
  return (
    <>
      <Header />
      <div className="row mt-3 m-auto containerPadding">RecentUpdate</div>{" "}
    </>
  );
}
