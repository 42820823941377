import React from "react";
import Carousel from "react-bootstrap/Carousel";
import CallIcon from "@mui/icons-material/Call";
import Footer from "./Footer";
export default function OverViewFooter() {
  return (
    <>
      <div className="row stlclr">
        <div className="col-md-4">
          <div
            className="row"
            style={{
              backgroundColor: "#DBDFEA",
              height: "600px",
              textAlign: "center",
            }}
          >
            <Carousel>
              <Carousel.Item>
                <div className="row mt-5">
                  <h6 style={{ color: "black" }}>
                    Manage your R.R.Industries on the go!
                  </h6>
                  <p className="fnt">
                    Experience the ease of managing your R.R.Industries with the
                    R.R.INDUSTRIES R.R.Industries mobile app for Android & iOS.
                  </p>
                </div>

                <div className="row">
                  <img
                    className="col-md-8 m-auto"
                    src="https://d31g2a6snus4ly.cloudfront.net/zbooks//assets/images/2x/mobile-carousel1-2x-7238fae3ca.png"
                    alt=""
                  />
                  <img
                    className="col-md-3 mt-4"
                    height={"60px"}
                    src="https://d31g2a6snus4ly.cloudfront.net/zbooks//assets/images/  R.R.Industries_mobile_qr-172d9694bd.png"
                    alt=""
                  />
                </div>
                <a href="#">Learn more</a>
              </Carousel.Item>
              <Carousel.Item>
                <div className="row mt-5">
                  <h6 style={{ color: "black" }}>
                    Manage your R.R.Industries on the go!
                  </h6>
                  <p className="fnt">
                    Experience the ease of managing your R.R.Industries with the
                    R.R.INDUSTRIES R.R.Industries mobile app for Android & iOS.
                  </p>
                </div>

                <div className="row">
                  <img
                    className="col-md-8 m-auto"
                    src="https://d31g2a6snus4ly.cloudfront.net/zbooks//assets/images/2x/mobile-carousel1-2x-7238fae3ca.png"
                    alt=""
                  />
                  <img
                    className="col-md-3 mt-4"
                    height={"60px"}
                    src="https://d31g2a6snus4ly.cloudfront.net/zbooks//assets/images/invetory_mobile_qr-172d9694bd.png"
                    alt=""
                  />
                </div>
                <a href="#">Learn more</a>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="col-md-8 mt-5">
          <div className="row  bottom cardpadding">
            <div className="col-md-4">
              <li className="fnt1"> OTHER R.R.INDUSTRIES APPS</li>
              <li className="fnt">Accounting Software</li>
              <li className="fnt">Subscription Billing</li>
              <li className="fnt">100% Free Invoicing Solution</li>
              <li className="fnt">Expense Reporting</li>
            </div>
            <div className="col-md-4">
              <li className="fnt1"> OTHER R.R.INDUSTRIES APPS</li>
              <li className="fnt">Accounting Software</li>
              <li className="fnt">Subscription Billing</li>
              <li className="fnt">100% Free Invoicing Solution</li>
              <li className="fnt">Expense Reporting</li>
            </div>
            <div className="col-md-4">
              <li className="fnt1"> OTHER R.R.INDUSTRIES APPS</li>
              <li className="fnt">Accounting Software</li>
              <li className="fnt">Subscription Billing</li>
              <li className="fnt">100% Free Invoicing Solution</li>
              <li className="fnt">Expense Reporting</li>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-2">
              <CallIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#06fbff3d",
                  borderRadius: "100px",
                }}
              />
            </div>
            <div className="col-md-10">
              <div className="row">
                <p className="fnt">
                  {" "}
                  You can directly talk to us every{" "}
                  <span style={{ fontWeight: "bold", color: "black" }}>
                    Monday to Friday 9:00 AM to 7:00 PM
                  </span>{" "}
                  (Toll Free)
                </p>
              </div>
              <div className="row">
                <p className="fnt">
                  R.R.Industries India Helpline: 18005726671
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <h6>© 2023, R.R.Industries Pvt. Ltd. All Rights Reserved.</h6>
          </div>
        </div>
      </div>
    </>
  );
}
