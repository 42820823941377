import React from 'react'
import Header from './Header'
import Footer from './Footer'

 function Purchase() {
  return (
    <>
      <Header />
      <div className="row mt-3 m-auto containerPadding">Purchase</div>{" "} <Footer />
    </>
  )
}

export default Purchase