import Button from "react-bootstrap/Button";
import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      <div className="row  text-center ">
        <p className="col-md-12 ">
          © 2023, R.R.Industries Pvt. Ltd. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
